.preloader-container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f8f9fa; /* Optional background color for the preloader page */
}
.spinner-grow {
	width: 5rem;
	height: 5rem;
	color: #007bff; /* Primary color for the spinner */
}
@media (max-width: 576px) {
	.spinner-grow {
		width: 3rem;
		height: 3rem;
	}
}
h4 {
	font-size: 20px;
	color: #343a40; /* Text color */
	font-weight: bold;
	margin-left: 10px;
}
